







































































import { Component, Vue } from "vue-property-decorator";
import Dialog from "@/app/shared/components/dialog/dialog.vue";
import CreateUser from "@/app/domains/settings/views/users/create-user.vue";
import CreateProject from "@/app/domains/settings/views/projects/create-project.vue";

enum ModalName {
  project = "project",
  user = "user",
}

@Component({
  components: {
    Dialog,
    CreateUser,
    CreateProject,
  },
})
export default class SettingsView extends Vue {
  private showModal = false;
  private modalNames = ModalName;
  private selectedModal: ModalName | null = null;

  private async toggleModal(modalName: ModalName | null): Promise<void> {
    this.selectedModal = modalName;
    if (this.selectedModal) this.showModal = true;
    else this.showModal = false;
  }

  private get showCreateUserButton(): boolean {
    return this.$route.name === "settings-user-selected";
  }

  private get showCreateProjectButton(): boolean {
    return this.$route.name === "settings-project-selected";
  }

  private get showUsers(): boolean {
    return this.$store.getters["configModule/roles"].includes(
      "ConfigurationUsers"
    );
  }

  private get showMedia(): boolean {
    return this.$store.getters["configModule/roles"].includes(
      "ConfigurationMedia"
    );
  }

  $refs!: {
    projectForm: CreateProject;
    userForm: CreateUser;
  };

  private async createUser(): Promise<void> {
    try {
      const { data } = await this.$refs.userForm.createUser();

      this.$store.commit("settingsModule/setUsers", [
        ...this.$store.getters["settingsModule/users"],
        data,
      ]);

      this.$message({
        message: "User created successfully",
        type: "success",
      });

      this.$router.push({
        name: "settings-user-selected",
        params: { userId: data.id },
      });

      this.toggleModal(null);
    } catch (error) {
      console.error(error);
    }
  }

  private async createProject(): Promise<void> {
    try {
      const project = (await this.$refs.projectForm.createProject()).data;

      this.$message({
        message: "Project created successfully",
        type: "success",
      });

      this.$store.commit("projectsModule/setProjects", [
        ...this.$store.getters["projectsModule/projects"],
        project,
      ]);

      if (project.id) {
        this.$router.push({
          name: "settings-project-selected",
          params: { settingsProject: project.id.toString() },
        });
      }
      this.toggleModal(null);
    } catch (error) {
      console.error(error);
    }
  }
}
