






































import { Component, Vue } from "vue-property-decorator";
import { AxiosResponse } from "axios";
import { Form } from "element-ui";
import AccountService from "@/app/shared/services/account.service";
import CheckboxContainer from "@/app/shared/components/checkbox-container/checkbox-container.vue";

import UserRolesValid from "@/app/domains/settings/utils/user-roles-valid";

import {
  UserForm,
  UserFormValidator,
} from "@/app/domains/settings/models/user-form.model";
import User from "@/app/domains/settings/models/user.model";

@Component({
  components: {
    CheckboxContainer,
  },
})
export default class CreateUser extends Vue {
  $refs!: {
    userForm: Form;
  };

  private userForm: UserForm = {
    id: "",
    login: "",
    password: "",
    confirmPassword: "",
    roles: [],
  };

  private userFormRules: UserFormValidator = {
    login: [
      {
        required: true,
        message: "Please input name",
        trigger: ["change", "blur"],
      },
    ],
    password: [
      {
        required: true,
        message: "Please input password",
        trigger: ["change", "blur"],
      },
      {
        min: 6,
        message: "Password must be at least 6 characters",
        trigger: ["blur", "change"],
      },
    ],
    confirmPassword: [
      {
        required: true,
        message: "Please confirm password",
        trigger: ["change", "blur"],
      },
      {
        min: 6,
        message: "Confirm password must be at least 6 characters",
        trigger: ["blur", "change"],
      },
      {
        validator: (rule, value, callback) => {
          if (value !== this.userForm.password) {
            callback("The passwords are different");
            return;
          }
          callback();
        },
        message: "The passwords are different",
        trigger: ["change", "blur"],
      },
    ],
  };

  async setUserRoles(): Promise<void> {
    try {
      const roles: Array<string> = (
        await this.$store.dispatch("settingsModule/getAllRoles")
      ).data;
      this.userForm.roles = roles.map((role) => {
        return {
          name: role,
          enabled: false,
        };
      });
    } catch (error) {
      console.error(error);
    }
  }

  async createUser(): Promise<AxiosResponse<User>> {
    try {
      await this.$refs.userForm.validate();
    } catch (error) {
      return Promise.reject("Invalid form");
    }

    if (!UserRolesValid(this.userForm.roles)) {
      this.$message({
        message: "At least one role must be selected.",
        type: "warning",
      });
      return Promise.reject("At least one role must be selected.");
    }

    try {
      return AccountService.createUser(this.userForm);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  created(): void {
    this.setUserRoles();
  }
}
