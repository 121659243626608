








import { Component, Vue } from "vue-property-decorator";
import { Validator } from "@/app/shared/validators/form-rules.types";
import Project from "@/app/shared/models/project.model";
import { Form } from "element-ui";
import { AxiosResponse } from "axios";

@Component
export default class CreateProject extends Vue {
  $refs!: {
    projectForm: Form;
  };

  private projectForm: Project = {
    name: "",
  };

  private projectFormRules: Validator<Project> = {
    name: [
      {
        required: true,
        message: "Please input name",
        trigger: ["change", "blur"],
      },
    ],
  };

  async createProject(): Promise<AxiosResponse<Project>> {
    try {
      await this.$refs.projectForm.validate();
    } catch (error) {
      return Promise.reject("Invalid form");
    }

    try {
      return await this.$store.dispatch(
        "settingsModule/createProject",
        this.projectForm
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
